<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_REFERRAL')" type="back" :settings="false" />
    </template>

    <div class="settings-container">
      <div class="container" style="display: flex;align-items: center;">
        <div class="profile-info profile-info-alt" v-if="shareLink">

         <div class="image-prof-cont">
            <div class="image-prof" :style="{'background-image' : 'url('+ $h.getImage(profileInfo.Image, 'USER') +')'}"></div>
             <h3 style="margin-top: 10px;">{{ $h.getFullName(profileInfo) }}</h3>
         </div>
          <div class="qr-container">
            <img :src="$h.getQRCode(shareLink)" style="width: 170px" loading="lazy" />        
          </div>
            
          <p class="ref-helper">{{ $t.getTranslation('LBL_WECHAT_SHARE_REFERRAL') }}</p>
            

        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject, defineAsyncComponent } from 'vue'
import { post, get } from '@/utils/axios'
import { logout } from '@/logics/auth.js'
import { useStore } from '@/store'
import { helpers } from '@/utils/helpers'
import { f7 } from 'framework7-vue'
import { configs } from '@/utils/configs.js'

// import ProfileNavigationComponent from '@/components/navigations/ProfileNavigationComponent.vue'

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

export default defineComponent({
  name: 'ReferralView',
  components: {
    ProfileNavigationComponent
  },
  props: { f7router: Object, f7route : Object },
  setup(props) {
    const $t = inject('$translation')

    const store = useStore()

    const userInfo = computed(() => store.getters['user/getData'])
    const userLogged = computed(() => store.getters['user/getToken'])

    const profileInfo = ref(false)
    const shareLink = ref(null)

    onMounted(() => {
      if(props?.f7route?.params?.code){
        if (userLogged.value == '') {
          return props.f7router.navigate({ name: "referral", query : { code : props.f7route.params.code } });
        }
        getProfileInfo()
      }
    })

    const getProfileInfo = async () => {
      profileInfo.value = await get('/user/info')

      if(profileInfo.value.ReferralCode != props.f7route.params.code){
       return props.f7router.navigate({ name: "profilePage" });
      }
      //render sharelink
      shareLink.value = configs.baseURL.replace('/api', '/r/' + profileInfo.value.ReferralCode)
    }

    return {
      shareLink,
      profileInfo
    }
  }
})
</script>

<style scoped>
.profile-info-alt{
      background: #f7f7f7;
    border-radius: 15px;
    flex-direction: column;margin-top: 20px;
    padding:15px;
}
.qr-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding:30px;
  border-radius: 20px;
}
.image-prof{
  background-size:cover;width: 70px;height: 70px;border-radius: 100%;
}
.image-prof-cont{
  padding:15px;
  margin-bottom: -30px;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ref-helper{
  max-width: 250px;
  text-align: center;
  padding-left:20px;
  padding-right:20px;
  margin-top: 5px;
}
</style>
